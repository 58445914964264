import React, { useState, useEffect } from "react";
import FilterPanel from "./FilterPanel";
import Plot3D from "./Plot3D";
import StepoutPlot from "./StepoutPlot";
import ArialStepoutPlot from "./ArialStepoutPlot";
import WellTable from "./WellTable";
import API from "../../utils/api"; // Use centralized API module

const App = () => {
  const [wells, setWells] = useState([]); // List of well names
  const [selectedWell, setSelectedWell] = useState(null); // Currently selected well
  const [filteredData, setFilteredData] = useState([]); // Raw data after fetching
  const [visibleData, setVisibleData] = useState([]); // Data filtered by slider range
  const [selectedColumns, setSelectedColumns] = useState(["MD", "Inc", "Azm", "NS", "EW", "TVD", "DLS"]); // Selected table columns
  const [sliderRange, setSliderRange] = useState([0, 1000]); // Slider range
  const [colorSettings, setColorSettings] = useState({
    colorColumn: "DLS",
    colorGradients: [
      { min: 0, max: 1, color: "#00FF00" }, // Green
      { min: 1, max: 1.5, color: "#FFA500" }, // Orange
      { min: 1.5, max: Infinity, color: "#FF0000" }, // Red
    ],
  });

  // Fetch well names when the app loads
  useEffect(() => {
    const loadWells = async () => {
      try {
        const response = await API.getDirectionalSurveyWells(); // Fetch well names
        setWells(response);
        if (response.length > 0) {
          setSelectedWell(response[0]); // Default to the first well
        }
      } catch (error) {
        console.error("Error fetching wells:", error);
      }
    };

    loadWells();
  }, []);

  // Fetch well data whenever a well is selected
  useEffect(() => {
    if (selectedWell) {
      const loadWellData = async () => {
        try {
          const response = await API.getDirectionalSurveyData(selectedWell); // Fetch data for the selected well
          setFilteredData(response);
          if (response.length > 0) {
            const maxMD = Math.max(...response.map((d) => d.MD));
            setSliderRange([0, maxMD]); // Set the slider range dynamically
          }
        } catch (error) {
          console.error(`Error fetching data for well ${selectedWell}:`, error);
        }
      };

      loadWellData();
    }
  }, [selectedWell]);

  // Update visibleData when sliderRange or filteredData changes
  useEffect(() => {
    const newData = filteredData.filter(
      (d) => d.MD >= sliderRange[0] && d.MD <= sliderRange[1]
    );
    setVisibleData(newData);
  }, [sliderRange, filteredData]);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f9f9f9",
      }}
    >
      {/* Filter Panel */}
      <div
        style={{
          flex: "1 0 300px",
          padding: "20px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
        }}
      >
        <FilterPanel
          wells={wells}
          selectedWell={selectedWell}
          onWellChange={setSelectedWell} // Update selected well
          availableColumns={["MD", "Inc", "Azm", "NS", "EW", "TVD", "DLS"]}
          onColumnSelectionChange={setSelectedColumns}
          sliderRange={sliderRange} // Pass slider range
          onSliderChange={setSliderRange}
          onColorSettingsChange={setColorSettings}
          onFilterChange={setFilteredData} // Update filtered data in App
        />
      </div>

      {/* Main Content Area */}
      <div
        style={{
          flex: "4",
          padding: "20px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "20px",
        }}
      >
        {/* 3D Well Path */}
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
            padding: "10px",
          }}
        >
          <Plot3D data={visibleData} colorSettings={colorSettings} />
        </div>

        {/* Stepout vs TVD */}
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
            padding: "10px",
          }}
        >
          <StepoutPlot data={visibleData} colorSettings={colorSettings} />
        </div>

        {/* Arial View (EW vs NS) */}
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
            padding: "10px",
          }}
        >
          <ArialStepoutPlot data={visibleData} colorSettings={colorSettings} />
        </div>

        {/* Well Data Table */}
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
            padding: "10px",
          }}
        >
          <WellTable data={visibleData} selectedColumns={selectedColumns} />
        </div>
      </div>
    </div>
  );
};

export default App;
