import React from "react";
import Plot from "react-plotly.js";

// Function to apply color gradients to data points
const applyColorGradients = (data, colorColumn, gradients) => {
  if (!data || !colorColumn || !gradients) return [];
  
  return data.map((point) => {
    const value = point[colorColumn];
    const gradient = gradients.find((g) => value >= g.min && value < g.max);
    return gradient ? gradient.color : "#000000"; // Default to black if no match
  });
};

const Plot3D = ({ data, colorSettings }) => {
  if (!data || data.length === 0) {
    return <p>No data available for the selected well.</p>;
  }

  // Ensure required properties are available
  if (
    !colorSettings ||
    !colorSettings.colorColumn ||
    !colorSettings.colorGradients
  ) {
    return <p>Color settings are not configured correctly.</p>;
  }

  // Apply color gradients
  const colors = applyColorGradients(data, colorSettings.colorColumn, colorSettings.colorGradients);

  return (
    <Plot
      data={[
        {
          x: data.map((d) => d.EW || 0), // Default to 0 if value is missing
          y: data.map((d) => d.NS || 0), // Default to 0 if value is missing
          z: data.map((d) => d.TVD || 0), // Default to 0 if value is missing
          type: "scatter3d",
          mode: "markers",
          marker: { size: 4, color: colors },
        },
      ]}
      layout={{
        title: "3D Well Path",
        scene: {
          xaxis: { title: "EW" },
          yaxis: { title: "NS" },
          zaxis: { title: "TVD", autorange: "reversed" }, // Reverse TVD
        },
        margin: { l: 0, r: 0, b: 0, t: 30 }, // Adjust margins
        height: 400,
      }}
    />
  );
};

export default Plot3D;
