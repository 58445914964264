import axios from "axios";

const API = {
  // Fetch list of directional survey wells
  getDirectionalSurveyWells: async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DIRECTIONAL_SURVEY_API_URL}/api/wells`);
      return response.data;
    } catch (error) {
      console.error("Error fetching directional survey wells:", error);
      throw error;
    }
  },

  // Fetch data for a specific well
  getDirectionalSurveyData: async (wellName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DIRECTIONAL_SURVEY_API_URL}/api/well/${wellName}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching directional survey data for well ${wellName}:`, error);
      throw error;
    }
  },

  // Fetch pump data for ESP pump designer
  getPumpData: async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ESP_PUMP_DESIGNER_API_URL}/api/pump-curves`);
      return response.data;
    } catch (error) {
      console.error("Error fetching pump data:", error);
      throw error;
    }
  },
};

export default API;
