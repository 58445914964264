import React from "react";
import PumpForm from "./PumpForm"; // Assume PumpForm is the main entry point for the form.

const App = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Ensures it stacks components properly.
        height: "100vh", // Makes it take up the full vertical height.
        width: "100%", // Takes the full width.
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f9f9f9",
      }}
    >
      {/* Pump Form */}
      <div
        style={{
          flex: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Center content in the middle.
          padding: "20px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
        }}
      >
        <PumpForm />
      </div>
    </div>
  );
};

export default App;
