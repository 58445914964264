import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import DirectionalSurvey from "./components/directional-survey/App";
import EspPumpDesigner from "./components/esp-pump-designer/App";
import PumpCurveCreator from "./components/esp-pump-designer/PumpCurveCreator"; // Import PumpCurveCreator

const App = () => {
  const [wellOptions, setWellOptions] = useState([]);
  const [selectedWell, setSelectedWell] = useState("");

  // Fetch well options
  const fetchWells = async () => {
    try {
      const response = await fetch("/api/wells"); // Replace with correct endpoint
      const data = await response.json();
      setWellOptions(data);
      setSelectedWell(data[0]); // Default to the first well
    } catch (error) {
      console.error("Error fetching wells:", error);
    }
  };

  useEffect(() => {
    fetchWells(); // Fetch wells on load
  }, []);

  return (
    <Router>
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Navigation Bar */}
        <nav
          style={{
            backgroundColor: "#333",
            color: "#fff",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Link
            to="/directional-survey"
            style={{
              color: "#fff",
              textDecoration: "none",
              fontSize: "18px",
            }}
          >
            Directional Survey
          </Link>
          <Link
            to="/esp-pump-designer"
            style={{
              color: "#fff",
              textDecoration: "none",
              fontSize: "18px",
            }}
          >
            ESP Pump Designer
          </Link>
          <Link
            to="/pump-curve-creator"
            style={{
              color: "#fff",
              textDecoration: "none",
              fontSize: "18px",
            }}
          >
            Pump Curve Creator
          </Link>
        </nav>

        {/* Main Content Area */}
        <div
          style={{
            flex: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f9f9f9",
          }}
        >
          <Routes>
            <Route path="/" element={<h1>Welcome to oilX</h1>} />
            <Route path="/directional-survey/*" element={<DirectionalSurvey />} />
            <Route path="/esp-pump-designer/*" element={<EspPumpDesigner />} />
            <Route path="/pump-curve-creator" element={<PumpCurveCreator />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
