import React from "react";
import Plot from "react-plotly.js";

const applyColorGradients = (data, colorColumn, gradients) => {
  return data.map((point) => {
    const value = point[colorColumn];
    const gradient = gradients.find((g) => value >= g.min && value < g.max);
    return gradient ? gradient.color : "#000000"; // Default to black if no match
  });
};

const ArialStepoutPlot = ({ data, colorSettings }) => {
  if (!data || data.length === 0) {
    return <p>No data available for the selected well.</p>;
  }

  const colors = applyColorGradients(data, colorSettings.colorColumn, colorSettings.colorGradients);

  return (
    <Plot
      data={[
        {
          x: data.map((d) => d.EW),
          y: data.map((d) => d.NS),
          mode: "markers",
          type: "scatter",
          marker: { size: 8, color: colors },
        },
      ]}
      layout={{
        title: "Arial View (EW vs NS)",
        xaxis: { title: "EW" },
        yaxis: { title: "NS" },
        height: 400,
      }}
    />
  );
};

export default ArialStepoutPlot;
