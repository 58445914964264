import React from "react";
import Plot from "react-plotly.js";

const applyColorGradients = (data, colorColumn, gradients) => {
  return data.map((point) => {
    const value = point[colorColumn];
    const gradient = gradients.find((g) => value >= g.min && value < g.max);
    return gradient ? gradient.color : "#000000"; // Default to black if no match
  });
};

const StepoutPlot = ({ data, colorSettings }) => {
  if (!data || data.length === 0) {
    return <p>No data available for the selected well.</p>;
  }

  const colors = applyColorGradients(data, colorSettings.colorColumn, colorSettings.colorGradients);

  return (
    <Plot
      data={[
        {
          x: data.map((d) => d.Stepout),
          y: data.map((d) => d.TVD),
          mode: "markers",
          type: "scatter",
          marker: { size: 8, color: colors },
        },
      ]}
      layout={{
        title: "Stepout vs TVD",
        xaxis: { title: "Stepout" },
        yaxis: { title: "TVD", autorange: "reversed" }, // TVD usually reversed
        height: 400,
      }}
    />
  );
};

export default StepoutPlot;
