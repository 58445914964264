import React from "react";

const WellTable = ({ data, selectedColumns }) => {
  const handleCopyToClipboard = () => {
    const tableData = data.map((row) =>
      selectedColumns.map((col) => row[col] || "").join("\t")
    );
    const clipboardContent = [selectedColumns.join("\t"), ...tableData].join("\n");
    navigator.clipboard.writeText(clipboardContent);
    alert("Table copied to clipboard!");
  };

  if (!data || data.length === 0) {
    return <p>No data available for the selected well.</p>;
  }

  return (
    <div>
      <div
        style={{
          maxHeight: "300px",
          overflowY: "auto",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              {selectedColumns.map((col) => (
                <th key={col} style={{ padding: "8px", textAlign: "left" }}>
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, idx) => (
              <tr key={idx}>
                {selectedColumns.map((col) => (
                  <td key={col} style={{ padding: "8px", textAlign: "left" }}>
                    {row[col] !== undefined ? row[col] : ""}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        onClick={handleCopyToClipboard}
        style={{
          marginTop: "10px",
          padding: "10px 15px",
          backgroundColor: "#007BFF",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Copy to Clipboard
      </button>
    </div>
  );
};

export default WellTable;
