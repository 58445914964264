import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { ChromePicker } from "react-color";
import API from "../../utils/api"; // Use the centralized API module

const FilterPanel = ({
  onFilterChange,
  availableColumns,
  onColumnSelectionChange,
  onSliderChange,
  onColorSettingsChange,
}) => {
  const [wellNames, setWellNames] = useState([]);
  const [selectedWell, setSelectedWell] = useState(null);
  const [sliderRange, setSliderRange] = useState([0, 1000]);
  const [originalMaxMD, setOriginalMaxMD] = useState(1000);
  const [selectedColumns, setSelectedColumns] = useState(availableColumns);
  const [colorColumn, setColorColumn] = useState("DLS");
  const [colorGradients, setColorGradients] = useState([
    { min: 0, max: 1, color: "#00FF00" },
    { min: 1, max: 1.5, color: "#FFA500" },
    { min: 1.5, max: Infinity, color: "#FF0000" },
  ]);
  const [activeGradientIndex, setActiveGradientIndex] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);

  // Fetch well names from the API
  useEffect(() => {
    const fetchWells = async () => {
      try {
        const wells = await API.getDirectionalSurveyWells();
        setWellNames(wells.sort((a, b) => a.localeCompare(b))); // Sort wells alphabetically
        if (wells.length > 0) {
          setSelectedWell(wells[0]); // Default to the first well
        }
      } catch (error) {
        console.error("Error fetching well names:", error);
      }
    };

    fetchWells();
  }, []);

  // Fetch data for the selected well
  useEffect(() => {
    if (!selectedWell) return;

    const fetchWellData = async () => {
      try {
        const wellData = await API.getDirectionalSurveyData(selectedWell);
        const maxMD = Math.max(...wellData.map((d) => d.MD));
        setSliderRange([0, maxMD]); // Automatically set slider to full range
        setOriginalMaxMD(maxMD);
        onSliderChange([0, maxMD]); // Notify parent of the updated slider range
        onFilterChange(wellData); // Update parent component with new data
      } catch (error) {
        console.error(`Error fetching data for well ${selectedWell}:`, error);
      }
    };

    fetchWellData();
  }, [selectedWell, onFilterChange, onSliderChange]);

  useEffect(() => {
    onColorSettingsChange({ colorColumn, colorGradients });
  }, [colorColumn, colorGradients, onColorSettingsChange]);

  const handleSliderChange = (event, newValue) => {
    setSliderRange(newValue);
    onSliderChange(newValue);
  };

  const handleColumnChange = (column) => {
    const updatedColumns = selectedColumns.includes(column)
      ? selectedColumns.filter((col) => col !== column)
      : [...selectedColumns, column];
    setSelectedColumns(updatedColumns);
    onColumnSelectionChange(updatedColumns);
  };

  const openColorPicker = (index) => {
    if (index < 0 || index >= colorGradients.length) {
      console.error("Invalid gradient index. Cannot open color picker.");
      return;
    }
    setActiveGradientIndex(index);
    setShowColorPicker(true);
  };

  const closeColorPicker = () => {
    setActiveGradientIndex(null);
    setShowColorPicker(false);
  };

  const updateColor = (color) => {
    if (activeGradientIndex === null || activeGradientIndex >= colorGradients.length) {
      console.error("Invalid gradient index. Cannot update color.");
      return;
    }
    const updatedGradients = [...colorGradients];
    updatedGradients[activeGradientIndex].color = color.hex;
    setColorGradients(updatedGradients);
    closeColorPicker();
  };

  return (
    <div style={{ padding: "20px", backgroundColor: "#f4f4f4", borderRadius: "8px", boxShadow: "0 4px 10px rgba(0,0,0,0.1)" }}>
      <h4>Filter Panel</h4>

      <Autocomplete
        options={wellNames}
        value={selectedWell}
        onChange={(event, newValue) => setSelectedWell(newValue)}
        renderInput={(params) => <TextField {...params} label="Well Name" />}
        fullWidth
        style={{ marginBottom: "20px" }}
      />

      <h5>Filter by MD Range</h5>
      <Slider
        value={sliderRange}
        onChange={handleSliderChange}
        min={0}
        max={originalMaxMD}
        valueLabelDisplay="auto"
        style={{ marginBottom: "20px" }}
      />

      <h5>Columns for Table</h5>
      {availableColumns.map((col) => (
        <FormControlLabel
          key={col}
          control={
            <Checkbox
              checked={selectedColumns.includes(col)}
              onChange={() => handleColumnChange(col)}
            />
          }
          label={col}
        />
      ))}

      <h5>Color Points by Column</h5>
      <Autocomplete
        options={availableColumns}
        value={colorColumn}
        onChange={(event, newValue) => setColorColumn(newValue)}
        renderInput={(params) => <TextField {...params} label="Color By" />}
        fullWidth
        style={{ marginBottom: "20px" }}
      />

      <h5>Color Gradients</h5>
      {colorGradients.map((gradient, index) => (
        <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
          <TextField
            type="number"
            label="Min"
            value={gradient.min}
            onChange={(e) => {
              const updatedGradients = [...colorGradients];
              updatedGradients[index].min = parseFloat(e.target.value);
              setColorGradients(updatedGradients);
            }}
            style={{ marginRight: "10px" }}
          />
          <TextField
            type="number"
            label="Max"
            value={gradient.max}
            onChange={(e) => {
              const updatedGradients = [...colorGradients];
              updatedGradients[index].max = parseFloat(e.target.value);
              setColorGradients(updatedGradients);
            }}
            style={{ marginRight: "10px" }}
          />
          <Button
            style={{
              backgroundColor: gradient.color,
              color: "#fff",
              padding: "8px 12px",
              marginRight: "10px",
            }}
            onClick={() => openColorPicker(index)}
          >
            Pick Color
          </Button>
          <Dialog open={showColorPicker && activeGradientIndex === index} onClose={closeColorPicker}>
            <ChromePicker color={gradient.color} onChangeComplete={updateColor} />
          </Dialog>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setColorGradients(colorGradients.filter((_, i) => i !== index))}
          >
            Remove
          </Button>
        </div>
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          setColorGradients([...colorGradients, { min: 0, max: 0, color: "#0000FF" }])
        }
      >
        Add Gradient
      </Button>
    </div>
  );
};

export default FilterPanel;
