import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css"; // Ensure this file exists, or comment this line out if you don't have it.

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
